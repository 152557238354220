import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  AddonTemplateTypes,
  MostPopularAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { useGetAccountInfo } from '@services/account'
import { useTranslation } from 'react-i18next'

import { getABTestsAddon } from './addons/ABTests'
import {
  getAgencyAddon,
  getAgencyMostPopularAddon
} from './addons/Agency/Agency'
import { getAIBackgroundRemover } from './addons/AIBackgroundRemover'
import { getAIContentGenerationAddon } from './addons/AIContentGeneration'
import { getAIMultiLanguageAddon } from './addons/AIMultiLanguage'
import { getAISeoGenerationAddon } from './addons/AISeoGeneration'
import { getCookieConsentAddon } from './addons/CookieConsent'
import { getECommerceAddon } from './addons/ECommerce'
import {
  getEventTrackerAddon,
  getEventTrackerMostPopularAddon
} from './addons/EventTracker'
import { getImportFromFigmaAddon } from './addons/ImportFromFigma'
import { getImportLandingPageAddon } from './addons/ImportLandingPage'
import { getIpDomainWhitelistingAddon } from './addons/IpDomainWhitelisting'
import { getLandingPageDesignAddon } from './addons/LandingPageDesign'
import { getLeadDataAnonymizationAddon } from './addons/LeadDataAnonymization'
import { getPopupsAddon } from './addons/Popups'
import { getProgrammaticLPAddon } from './addons/ProgrammaticLP'
import { getSmartSectionsAddon } from './addons/SmartSections'

export const useAddons = () => {
  const { t } = useTranslation()

  const { isAgency } = useUserContext()
  const FIGMA_IMPORT_FF = useFeatureFlags('FIGMA_IMPORT')
  const PRICING_2022_FF = useFeatureFlags('PRICING_2022')
  const { hasCustomOffer } = useGetAccountInfo()

  const HIDE_ADDON_PROFILE_FF = useFeatureFlags('HIDE_ADDON_PROFILE')

  const hasAccessToProgrammaticLP = useFeatureFlags('PROGRAMMATIC_LP')
  const UNBRANDED_CONTENT_FF = useFeatureFlags('UNBRANDED_CONTENT')

  const addons: AddonTemplateTypes[] = [
    getEventTrackerAddon(t, isAgency),
    getProgrammaticLPAddon(t, Boolean(hasAccessToProgrammaticLP), {
      UNBRANDED_CONTENT_FF
    }),
    getIpDomainWhitelistingAddon(t, {
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getCookieConsentAddon(t, {
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getLeadDataAnonymizationAddon(t, {
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getAgencyAddon(t, { isHidden: Boolean(hasCustomOffer) }, isAgency),
    getLandingPageDesignAddon(t),
    getImportLandingPageAddon(t),
    getAIMultiLanguageAddon(t, { UNBRANDED_CONTENT_FF }),
    getAIContentGenerationAddon(t, {
      UNBRANDED_CONTENT_FF,
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getAISeoGenerationAddon(t, {
      UNBRANDED_CONTENT_FF,
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getAIBackgroundRemover(t, {
      UNBRANDED_CONTENT_FF,
      isHidden: Boolean(HIDE_ADDON_PROFILE_FF)
    }),
    getSmartSectionsAddon(t),
    getECommerceAddon(t),
    getPopupsAddon(t),
    getABTestsAddon(t),
    getImportFromFigmaAddon(t, { isHidden: !FIGMA_IMPORT_FF, PRICING_2022_FF })
  ]

  const mostPopularAddons: MostPopularAddonTemplate[] = [
    getAgencyMostPopularAddon(
      t,
      {
        isHidden: Boolean(hasCustomOffer)
      },
      isAgency
    ),
    getEventTrackerMostPopularAddon(t)
  ]

  return { addons, mostPopularAddons }
}
