import { ADDONS_IDS } from '@constants/addonsIds'
import Figma from '@images/addons/icons/Figma.svg'
import {
  FEATURE,
  NEW_NO_ICON
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import {
  ADDON_GROUP,
  POC_TEST,
  PocTestAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getImportFromFigmaAddon: (
  t: TFunction<'translation', undefined>,
  {
    isHidden,
    PRICING_2022_FF
  }: { isHidden: boolean; PRICING_2022_FF?: boolean }
) => PocTestAddonTemplate = (t, { isHidden, PRICING_2022_FF }) => ({
  type: POC_TEST,
  name: t('addons.store.import-from-figma.name'),
  title: t('addons.store.import-from-figma'),
  tileDescription: (
    <Trans i18nKey='addons.store.import-from-figma.description' />
  ),
  pageDescription: t('addons.store.import-from-figma.page.description'),
  badges: [NEW_NO_ICON, FEATURE],
  icon: Figma,
  // TODO: no thumbnail image for this addon
  imageSrc: Figma,
  id: ADDONS_IDS.IMPORT_FROM_FIGMA,
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  price: {
    1: !PRICING_2022_FF
      ? { USD: 49, PLN: 187, GBP: 45, BRL: 199, EUR: 49 }
      : {
          USD: 10,
          PLN: 50,
          GBP: 8,
          BRL: 60,
          EUR: 10
        }
  },
  pricing: getPricing(t),
  faq: getFAQ(t),
  isBeingTested: true,
  isHidden,
  group: ADDON_GROUP.OPTIMIZE
})
