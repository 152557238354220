import { isEmpty } from '@helpers/data'
import { Loader } from '@landingi/landingi-ui-kit'
import { Button } from '@ui-kit/Button'
import { Dropdown, DropdownLevelTypes } from '@ui-kit/Dropdown'
import { DropdownElement, DropdownListSize } from '@ui-kit/DropdownList'
import { Search } from '@ui-kit/Search'
import { NeutralColor } from '@ui-kit/types/color'
import { Fragment, ReactNode } from 'react'

import { getSelectedItem } from './helpers'
import styles from './Select.module.scss'
import { Trigger } from './Trigger'
import { SelectOption } from './types'

export interface SelectProps<T> {
  id?: string
  i18n?: { placeholder?: string }
  options: SelectOption<T>[]
  onChange?: (value: T, key?: string) => void
  value?: T
  loadMoreButton?: {
    shouldRender?: boolean
    text?: string
    onClick?: () => void
  }
  searcher?: {
    shouldRender?: boolean
    placeholder?: string
    onChange: () => void
    liveChanges?: boolean
  }
  onOpen?: () => void
  isLoading?: boolean
  border?: { bottom: Extract<NeutralColor, 'neutral-2'> }
  overflowStyle?: {
    overflow?: 'scroll' | 'auto'
    maxHeight?: string
  }
  emptyMessage?: ReactNode
  level?: DropdownLevelTypes
  size?: DropdownListSize
  layerClassName?: string | string[]
  'data-testid'?: string
}

export const Select = <T extends number | string | undefined>({
  id,
  i18n,
  options,
  onChange,
  value,
  loadMoreButton = {
    text: 'Load more',
    shouldRender: false
  },
  searcher = {
    shouldRender: false,
    placeholder: '',
    liveChanges: false,
    onChange: () => {}
  },
  onOpen,
  isLoading = false,
  border,
  overflowStyle = {
    overflow: 'scroll',
    maxHeight: '220px'
  },
  level,
  emptyMessage = '',
  size = 'full-width',
  layerClassName,
  'data-testid': dataTestId = 'select'
}: SelectProps<T>) => {
  const selectedItem = getSelectedItem(value, options)

  const trigger = ({
    isOpen,
    isDisabled
  }: {
    isOpen: boolean
    isDisabled: boolean
  }) => (
    <Trigger
      isOpen={isOpen}
      isDisabled={isDisabled}
      placeholder={i18n?.placeholder}
      selectedItem={selectedItem}
    />
  )

  const handleChange = (value: T) => {
    onChange?.(value, id)
  }

  return (
    <Dropdown
      trigger={trigger}
      placement='bottom-start'
      size={size}
      onOpen={onOpen}
      level={level}
      data-testid={dataTestId}
      layerClassName={layerClassName}
    >
      {({ close }) => (
        <Fragment>
          {searcher?.shouldRender && (
            <div className={styles['searcher-container']}>
              <Search
                setSearchPhrase={searcher.onChange}
                i18n={{ placeholder: searcher.placeholder }}
              />
            </div>
          )}

          {isLoading ? (
            <Loader />
          ) : (
            <div style={overflowStyle}>
              {isEmpty(options) ? (
                emptyMessage
              ) : (
                <Fragment>
                  {options.map(({ value, label }) => (
                    <DropdownElement
                      key={value}
                      padding={{ x: 20, y: 15 }}
                      onClick={() => {
                        handleChange(value)
                        close()
                      }}
                      border={border}
                    >
                      {label}
                    </DropdownElement>
                  ))}

                  {loadMoreButton.shouldRender && (
                    <Button
                      variant='text-neutral'
                      align='start'
                      icon='icon-arrow-down'
                      fullWidth
                      onClick={loadMoreButton.onClick}
                    >
                      {loadMoreButton.text}
                    </Button>
                  )}
                </Fragment>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Dropdown>
  )
}
