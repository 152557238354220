import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import LANDINGS from '@routes/path/landings'
import { Badge, Icon, Spreader, Tooltip } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Row } from 'simple-flexbox'

import { useGetBadges } from './hooks/useGetBadges'

export const Badges = () => {
  const { landing } = useDashboardContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { group_name, group_uuid, camino_lid, new_camino_lid } = landing

  const handleGroup = () => {
    if (!group_uuid) return

    navigate(generatePath(LANDINGS.LANDINGS_GROUP, { identifier: group_uuid }))
  }

  const badges = useGetBadges()

  return (
    <Row>
      {Object.entries(badges).map(
        ([key, { isEnabled, label, icon, onClick }]) => {
          if (!isEnabled) return null

          return (
            <Fragment key={key}>
              <Badge
                variant='neutral1-filled'
                onClick={onClick}
                weight={600}
                isTextUppercase={false}
                hasCursorPointer={Boolean(onClick)}
              >
                <Icon icon={icon} color='neutral-6' size={12} />
                <Spreader spread={5} />
                {label}
              </Badge>

              <Spreader spread={5} />
            </Fragment>
          )
        }
      )}

      {camino_lid || new_camino_lid ? (
        <Tooltip
          content={t('landings.dashboard.camino.tooltip', {
            lid: camino_lid
          })}
          placement='top'
          disabled={Boolean(new_camino_lid)}
        >
          <Badge variant='neutral1-filled' isTextUppercase={false} weight={600}>
            {t('landings.dashboard.camino.title')}
          </Badge>
        </Tooltip>
      ) : null}

      {group_name ? (
        <Badge
          variant='info-filled'
          onClick={handleGroup}
          isTextUppercase={false}
          hasCursorPointer
          weight={600}
        >
          <Icon icon='icon-folder' color='white' size={12} />

          <Spreader spread={5} />

          {group_name}
        </Badge>
      ) : null}
    </Row>
  )
}

Badges.displayName = 'LandingDashboardHeaderBadges'
