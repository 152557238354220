import { ADDONS_IDS } from '@constants/addonsIds'
import CookieBot from '@images/addons/icons/CookieBot.svg'
import {
  FEATURE,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import {
  ADDON_GROUP,
  POC_TEST,
  PocTestAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getCookieConsentAddon: (
  t: TFunction<'translation', undefined>,
  { isHidden }: { isHidden: boolean }
) => PocTestAddonTemplate = (t, { isHidden }) => ({
  type: POC_TEST,
  name: t('addons.store.cookiebot.name'),
  title: t('addons.store.cookiebot'),
  tileDescription: <Trans i18nKey='addons.store.cookie.bot.description' />,
  pageDescription: t('addons.store.cookie.bot.page.description'),
  badges: [FEATURE, SEVEN_DAYS_FREE_TRIAL],
  icon: CookieBot,
  // TODO: no thumbnail image for this addon
  imageSrc: CookieBot,
  id: ADDONS_IDS.COOKIE_CONSENT,
  uuid: 'TODO',
  overview: getOverview(t),
  features: getFeatures(),
  useCases: getUseCases(t),
  price: {
    1: {
      USD: 10,
      PLN: 50,
      GBP: 8,
      BRL: 60,
      EUR: 10
    }
  },
  pricing: getPricing(t),
  faq: getFAQ(t),
  isBeingTested: true,
  isHidden,
  group: ADDON_GROUP.OPTIMIZE
})
