import { UNEXPECTED_ERROR } from '@constants/errorMessages'
import { BRAINTREE_ERROR } from '@constants/errorNames'
import { t } from 'i18next'

/**
 * getLoginErrorMessage - return error message
 * @function getLoginErrorMessage
 * @param {string} code - code
 * @return {string} returns error message id
 */
export const getLoginErrorMessage = code => {
  switch (code) {
    case 'A0010':
      return 'registration.flow.sign.in.a0010'
    case 'A0011':
      return 'registration.flow.sign.in.a0011.banned'
    default:
      return 'registration.flow.sign.in.wrong.data'
  }
}

/**
 * getGoogleLoginErrorMessage - return error message
 * @function getGoogleLoginErrorMessage
 * @param {string} code - code
 * @return {string} returns error message id
 */
export const getGoogleLoginErrorMessage = code => {
  switch (code) {
    case 'A0009':
      return 'registration.flow.google.sign.in.a0009'
    case 'A0010':
      return 'registration.flow.sign.in.a0010'
    case 'A0011':
      return 'registration.flow.sign.in.a0011.banned'
    case 'A0012':
      return 'registration.flow.google.sign.in.a0009'
    default:
      return 'registration.flow.sign.in.wrong.data'
  }
}

/**
 * getRegistrationErrorMessage - return error message
 * @function getRegistrationErrorMessage
 * @param {string} code - code
 * @return {string} returns error message id
 */
export const getRegistrationErrorMessage = error => {
  const code = error?.code

  if (!code) {
    return 'toast.error.something.went.wrong'
  }

  switch (code) {
    case 'A0001':
      if (error.violations[0].message === 'Email address is already taken!') {
        return 'registration.flow.sign.up.a0008'
      }

      if (
        error.violations[0].message ===
        'This value is too long. It should have 64 characters or less.'
      ) {
        return 'registration.flow.password.too.long'
      }

      if (error.violations[0].field === '[firstName]') {
        return 'registration.flow.please.enter.valid.name'
      }

      if (error.violations[0].message === 'Password is too weak!') {
        return 'registration.flow.password.too.weak'
      }

      if (error.violations[0].field === '[lastName]') {
        return 'toast.error.something.went.wrong'
      }

      return 'registration.flow.sign.up.other.error'
    case 'A0008':
      return 'registration.flow.sign.up.a0008'
    default:
      return 'registration.flow.sign.up.other.error'
  }
}

/**
 * getCreditCardStepErrorMessage - return error message
 * @function getCreditCardStepErrorMessage
 * @param {object}  - error
 * @return {string} returns error message id
 */
export const getCreditCardStepErrorMessage = (
  error,
  country,
  setFieldError
) => {
  if (
    error.name === BRAINTREE_ERROR ||
    error?.response.data.error?.message === UNEXPECTED_ERROR
  ) {
    return 'registration.flow.credit.card.step.wrong.credit.card.data'
  }

  switch (error?.response?.data?.error?.code) {
    case 'P0000':
      return 'error.please.try.again'
    case 'P0004':
      if (
        error?.response?.data?.error?.violations[0]?.message ===
        'This is not a valid NIP number.'
      ) {
        if (country?.value === 'PL') {
          setFieldError(
            'vat',
            t('registration.flow.credit.card.step.wrong.nip.pl')
          )

          return 'registration.flow.credit.card.step.wrong.nip.pl'
        }

        setFieldError('vat', t('registration.flow.credit.card.step.wrong.nip'))

        return 'registration.flow.credit.card.step.wrong.nip'
      }

      break
    case 'P0007':
      setFieldError(
        'postCode',
        t(
          'registration.flow.credit.card.step.postal.code.contains.invalid.characters'
        )
      )
      return 'registration.flow.credit.card.step.postal.code.contains.invalid.characters'
    case 'P0009':
      if (country?.value === 'PL') {
        setFieldError(
          'vat',
          t('registration.flow.credit.card.step.wrong.nip.pl')
        )

        return 'registration.flow.credit.card.step.wrong.nip.pl'
      }

      setFieldError('vat', t('registration.flow.credit.card.step.wrong.nip'))
      return 'registration.flow.credit.card.step.wrong.nip'
    case 'P0017':
      if (country?.value === 'PL') {
        setFieldError(
          'vat',
          t('registration.flow.credit.card.step.wrong.nip.pl')
        )

        return 'registration.flow.credit.card.step.wrong.nip.pl'
      }

      setFieldError('vat', t('registration.flow.credit.card.step.wrong.nip'))

      return 'registration.flow.credit.card.step.wrong.nip'
    case 'P0013':
      return 'registration.flow.credit.card.step.please.verify.credit.card'
    case 'P0014':
      return 'registration.flow.credit.card.step.please.verify.credit.card'
    case 'P0015':
      return 'registration.flow.credit.card.step.please.verify.credit.card'
    case 'P0016':
      return 'registration.flow.credit.card.step.please.verify.credit.card'
    default:
      return 'toast.error.something.went.wrong'
  }

  return 'toast.error.something.went.wrong'
}
