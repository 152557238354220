import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { LANDINGS, SMART_SECTIONS } from '@routes/path'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

interface BadgeItem {
  isEnabled: boolean
  label: string
  icon: string
  onClick?: () => void
}

export const useGetBadges = () => {
  const { landing } = useDashboardContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const {
    smart_sections_count,
    schedule_enabled,
    identifier,
    test_enabled,
    is_programmatic
  } = landing

  const badges: Record<string, BadgeItem> = {
    programmatic: {
      isEnabled: is_programmatic,
      label: t('word.programmatic'),
      icon: 'icon-programmatic'
    },
    test: {
      isEnabled: test_enabled,
      label: t('word.test'),
      icon: 'icon-random',
      onClick: () =>
        navigate(generatePath(LANDINGS.LANDINGS_AB, { identifier }))
    },
    schedule: {
      isEnabled: schedule_enabled,
      label: t('word.schedule'),
      icon: 'icon-calendar',
      onClick: () =>
        navigate(generatePath(LANDINGS.LANDINGS_SCHEDULE, { identifier }))
    },
    smartSections: {
      isEnabled: Boolean(smart_sections_count),
      label: t('word.smartsection.count', {
        count: smart_sections_count
      }),
      icon: 'icon-smart_section',
      onClick: () => {
        const paramsForUrl = new URLSearchParams({
          uuid: identifier
        })

        navigate(`${SMART_SECTIONS.SMART_SECTIONS}?${paramsForUrl}`)
      }
    }
  }

  return badges
}
