import { MODAL_GENERATE_QR_CODE } from '@components/Modals/ModalGenerateQRCode'
import { CLICK_ON_QR_CODE_MODAL, DASHBOARD } from '@constants/mixpanel'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { isTestDomain } from '@helpers/domains'
import { useLazyService } from '@hooks/useLazyService'
import { EditableLabel } from '@landingi/landingi-ui-kit'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import LANDINGS from '@routes/path/landings'
import { renameLanding, RenameLandingRequest } from '@services/landings/rename'
import { mixpanelEvent } from '@services/mixpanel'
import { Button, Icon, Spacer, Spreader, Tooltip } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'

import { Actions } from './Actions'
import { Badges } from './Badges'
import styles from './LandingInfo.module.scss'
import { LinkToLP } from './LinkToLP'
import { PublicationStatus } from './PublicationStatus'

export const LandingInfo = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { landing, setNewLandingData } = useDashboardContext()

  const { domain_url, identifier: landingUuid, name } = landing

  const isFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const isSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const hasAccessToPublishingOptions = useAcl(
    'landing_pages.landing.publishing_options'
  )

  const hasAccessToRename = useAcl('landing_pages.landing.rename')

  // Render link for all domains on paid plan or free plan with ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderLink = !(
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
  )

  const hasNotAccessToQRCode =
    isTestDomain(domain_url) && isFreePlanRestrictions

  const handleOpenQRCodeModal = () => {
    mixpanelEvent({
      name: CLICK_ON_QR_CODE_MODAL,
      properties: {
        click_location: DASHBOARD
      }
    })

    NiceModal.show(MODAL_GENERATE_QR_CODE, {
      url: `https://${domain_url}`,
      clickLocation: DASHBOARD
    })
  }

  const [renameLandingService, { isLoading }] =
    useLazyService<RenameLandingRequest>(renameLanding, {
      successToastText: t('toast.saved.success'),
      errorToastText: t('toast.saved.fail'),
      onSuccess: (res, { newName }) => setNewLandingData({ name: newName })
    })

  const handleRenameLanding = async (name: string) => {
    await renameLandingService({
      uuid: landingUuid,
      newName: name
    })
  }

  const handleOpenPublishingOptions = () => {
    const path = generatePath(LANDINGS.LANDINGS_PUBLISHING_OPTIONS.MAIN, {
      identifier: landingUuid
    })

    navigate(`${path}?dashboard`)
  }

  return (
    <Column style={{ width: '100%' }}>
      <Row justifyContent='space-between' alignItems='center'>
        {/* //TODO - rewrite EditableLabel to the new UI-KIT and replace */}
        <EditableLabel
          initialName={name}
          isClickable={hasAccessToRename}
          placeholder={t('word.landings')}
          onChange={handleRenameLanding}
          isLoading={isLoading}
          isDisabled={!hasAccessToRename}
          tooltip={{
            focused: t('dashboard.header.hover.tooltip.focused'),
            notFocused: t('dashboard.header.hover.tooltip.not.focused')
          }}
        />

        <Actions />
      </Row>

      <Spacer space={10} />

      <Row alignItems='center' className={styles['landing__info--domain']}>
        <PublicationStatus />

        {shouldRenderLink && <LinkToLP />}

        {!isSemrushIntegration && (
          <Tooltip
            content={
              hasNotAccessToQRCode
                ? t('modal.generate.qr.code.tooltip.disabled')
                : t('modal.generate.qr.code.tooltip')
            }
          >
            <Button
              variant='text-primary'
              size={12}
              onClick={handleOpenQRCodeModal}
              isSquare
              disabled={hasNotAccessToQRCode}
            >
              <Icon icon='icon-qr-code' />
            </Button>
          </Tooltip>
        )}

        {hasAccessToPublishingOptions ? (
          <Fragment>
            <Spreader spread={5} />

            <Button
              variant='text-primary'
              size={12}
              onClick={handleOpenPublishingOptions}
            >
              {t('word.change.url')}
            </Button>
          </Fragment>
        ) : null}
      </Row>

      <Spacer space={10} />

      <Badges />
    </Column>
  )
}
