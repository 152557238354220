import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { Button, CustomLink, Icon, Spreader, Tooltip } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export const LinkToLP = () => {
  const { t } = useTranslation()

  const { landing } = useDashboardContext()

  const { domain_url, is_published } = landing

  const [handleCopy] = useCopyToClipboard(domain_url)

  return (
    <Fragment>
      <CustomLink
        href={`https://${domain_url}`}
        target='_blank'
        isDisabled={!is_published}
      >
        {domain_url}
      </CustomLink>

      <Spreader spread={30} />

      <Tooltip content={t('copy.link.to.clipboard')}>
        <Button variant='text-primary' size={12} onClick={handleCopy} isSquare>
          <Icon icon='icon-link' />
        </Button>
      </Tooltip>
    </Fragment>
  )
}
