import { ANNUALLY, BillingPeriodType, MONTHLY } from '@constants/billingPeriods'
import { CurrencyType } from '@constants/currencies'
import { useUserContext } from '@contexts/user'
import { useAddons } from '@pages/AddonsStore/routes/Addons/addons'
import { AddonTemplateTypes } from '@pages/AddonsStore/routes/types'
import { useGetUsedProductAddOnUuids } from '@services/addons'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'

const AddonsStoreContext = createContext<{
  chosenBillingPeriod: BillingPeriodType
  setChosenBillingPeriod: Dispatch<SetStateAction<BillingPeriodType>>
  accountCurrency: CurrencyType
  getAddon: (addonName?: string) => AddonTemplateTypes | undefined
  hasSubscription?: boolean
  hasUsedTrial?: boolean
  usedProductAddOnUuids?: string[]
  isLoadingUsedProductAddOnUuids?: boolean
  planRecordKey?: string
} | null>(null)

type ProviderProps = {
  children: ReactNode
}

export const AddonsStoreProvider: FC<ProviderProps> = ({ children }) => {
  const { accountCurrency, billingPeriod, hasSubscription, planRecordKey } =
    useUserContext()

  const { usedProductAddOnUuids, isLoading: isLoadingUsedProductAddOnUuids } =
    useGetUsedProductAddOnUuids()

  const { addons } = useAddons()

  const getAddon = (addonName?: string) =>
    addons.find(({ id }) => id === addonName)

  const [chosenBillingPeriod, setChosenBillingPeriod] = useState(
    billingPeriod && [MONTHLY, ANNUALLY].includes(billingPeriod)
      ? billingPeriod
      : MONTHLY
  )

  return (
    <AddonsStoreContext.Provider
      value={{
        chosenBillingPeriod,
        setChosenBillingPeriod,
        accountCurrency,
        getAddon,
        hasSubscription,
        usedProductAddOnUuids,
        isLoadingUsedProductAddOnUuids,
        planRecordKey
      }}
    >
      {children}
    </AddonsStoreContext.Provider>
  )
}

export const useAddonsStoreContext = () => {
  const addonsStore = useContext(AddonsStoreContext)

  if (!addonsStore) {
    throw new Error(
      'useAddonsStoreContext must be used inside AddonsStoreProvider'
    )
  }

  return addonsStore
}
