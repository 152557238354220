import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { Spreader, Thumbnail } from '@ui-kit'
import { Row } from 'simple-flexbox'

import { LandingInfo } from './LandingInfo'

export const InfoNew = () => {
  const { landing } = useDashboardContext()

  const { thumbnail_url } = landing

  return (
    <Row style={{ width: '100%' }}>
      <Thumbnail thumbnailUrl={thumbnail_url} variant='big' />

      <Spreader spread={15} />

      <LandingInfo />
    </Row>
  )
}

InfoNew.displayName = 'LandingDashboardHeaderInfoNew'
