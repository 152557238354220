import { useStyles } from '@hooks/useStyles'
import imagePlaceholder from '@images/placeholder-no-image-big.svg'
import { Image } from '@ui-kit/Image'
import { FC } from 'react'
import { Row } from 'simple-flexbox'

import styles from './Thumbnail.module.scss'

export interface ThumbnailProps {
  thumbnailUrl?: string
  onClick?: () => void
  variant?: 'small' | 'big'
  isActive?: boolean
  className?: string | string[]
  'data-testid'?: string
}

export const Thumbnail: FC<ThumbnailProps> = ({
  thumbnailUrl,
  onClick,
  variant = 'small',
  isActive = true,
  className = '',
  'data-testid': dataTestId = 'thumbnail'
}) => {
  const wrapperStyles = useStyles(
    {
      [styles.thumbnailWrapper]: true,
      [styles['thumbnailWrapper--clickable']]: onClick
    },
    className
  )

  const imageStyles = useStyles({
    [styles.thumbnail]: true,
    [styles['thumbnail--disactive']]: !isActive
  })

  const sizeStyles = {
    small: { width: '75px', height: '70px' },
    big: { width: '150px', height: '140px' }
  } as const

  const { width, height } = sizeStyles[variant]

  return (
    <Row
      className={wrapperStyles}
      onClick={onClick}
      data-testid='thumbnail-wrapper'
    >
      <Image
        className={imageStyles}
        src={thumbnailUrl || imagePlaceholder}
        width={width}
        height={height}
        data-testid={dataTestId}
      />
    </Row>
  )
}
