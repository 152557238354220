import { NotFound } from '@pages/NotFound/routes/NotFound'
import { useDownloadLeads } from '@services/leads/useDownloadLeads'
import { Loader } from '@ui-kit'
import { useParams } from 'react-router-dom'

export const DownloadLeads = () => {
  const params = useParams()

  const { data, error, isLoading } = useDownloadLeads({
    key: params.key || '',
    file: params.fileName || ''
  })

  if (error?.status === 404) {
    return <NotFound />
  }

  if (isLoading) {
    return <Loader />
  }

  if (data) {
    window.location.assign(data.url)
  }

  return <div />
}
