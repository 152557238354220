import { PLANS } from '@constants/plans'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useCreditCardStepContext } from '@pages/Authentication/contexts/creditCardStep'
import styles from '@pages/Authentication/routes/ComparePlans/ComparePlans.module.scss'

import { Plan } from './components/Plan'
import { PlanRecordKey } from './types'

interface PlanType {
  recordKey: PlanRecordKey
  name: string
}

export const Plans = ({ billingPeriod }: { billingPeriod: number }) => {
  const { currentPlans } = useCreditCardStepContext() as {
    currentPlans: PlanType[]
  }

  const hasAccessToEnterprisePlan = useFeatureFlags('ACCESS_TO_ENTERPRISE_PLAN')

  const filteredCurrentPlans = currentPlans.filter(plan => {
    if (hasAccessToEnterprisePlan) {
      return plan.recordKey !== PLANS.UNLIMITED_22
    }

    return plan.recordKey !== PLANS.ENTERPRISE_25
  })

  return (
    <div className={styles.plans}>
      {filteredCurrentPlans.map(plan => {
        const { recordKey, name } = plan

        return (
          <Plan
            key={recordKey}
            planName={name}
            planRecordKey={recordKey}
            billingPeriod={billingPeriod}
          />
        )
      })}
    </div>
  )
}
