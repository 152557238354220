import { useStyles } from '@hooks/useStyles'
import { getColumnWidth } from '@ui-kit/Table/helpers'
import { Column, ItemBase, RowProps } from '@ui-kit/Table/types'

import styles from './Row.module.scss'

export const Row = <Item extends ItemBase>({
  id,
  columns,
  data,
  rowActions,
  hasFullBorder,
  index,
  setSelectedRow,
  height,
  onClickEvent,
  style,
  columnStyles,
  selectedRow
}: RowProps<Item>) => {
  const rowStyles = useStyles({
    [styles.row]: true,
    [styles['row--full-border']]: hasFullBorder,
    [styles['row--without-top-border']]: hasFullBorder && index !== 0,
    [styles['row--is-hovered']]: selectedRow === id
  })

  const handleClick = (column: Column<Item>) => onClickEvent?.(data, column)

  return (
    <div
      id={id}
      data-testid='table-row'
      className={rowStyles}
      style={{
        gridTemplateColumns: columns
          .map(column => getColumnWidth(column.width, data))
          .join(' '),
        height,
        ...style?.(data, { isHovered: selectedRow === id })
      }}
      onMouseEnter={() => {
        setSelectedRow?.(id)
      }}
    >
      {columns.map(column => (
        <div
          className={styles.cell}
          key={column.id}
          onClick={() => handleClick(column)}
          style={columnStyles?.(column, { isHovered: selectedRow === id })}
        >
          {column.render ? column.render(data) : data[column.id]}
        </div>
      ))}

      {rowActions && rowActions(data) !== null && (
        <div className={styles.rowActions}>{rowActions(data)}</div>
      )}
    </div>
  )
}
