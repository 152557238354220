import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import { t } from 'i18next'
import { mixed, object, string } from 'yup'

const RegistrationFormSchema = () =>
  object().shape({
    firstName: string()
      .trim()
      .max(250)
      .required(t('form.validation.isRequired')),
    lastName: mixed().test('is-empty', 'This field must be empty', value => {
      if (value) {
        return false
      }

      return true
    }),
    email: string()
      .email(t('registration.flow.sign.up.invalid.mail'))
      .max(250)
      .required(t('form.validation.isRequired')),
    password: string()
      .required(t('form.validation.isRequired'))
      .test('contain lowercase', ' ', value => value && hasLowerCase(value))
      .test('contain uppercase', ' ', value => value && hasUpperCase(value))
      .test('contain number', ' ', value => value && hasNumber(value))
      .min(10, ' ')
  })

export default RegistrationFormSchema
