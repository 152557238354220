import { useFeatureFlags } from '@contexts/featureFlags'
import { isTestDomain } from '@helpers/domains'
import { useDashboardContext } from '@pages/Landings/routes/Dashboard/context'
import { PublicationType } from '@pages/Landings/routes/Landings/components/LandingsTable/components/PublicationType'
import { Paragraph, Spreader, StatusIcon } from '@ui-kit'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export const PublicationStatus = () => {
  const { t } = useTranslation()

  const { landing } = useDashboardContext()

  const { domain_url, is_published, publication_type } = landing

  const isFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')
  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  // Render publication type only for published landings and only non-test domains on free plan
  const shouldRenderPublicationType =
    is_published &&
    !(
      isTestDomain(domain_url) &&
      isFreePlanRestrictions &&
      !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)
    )

  // Render publication status only for test domains on free plan without ALLOW_TEST_AUTODOMAIN feature flag
  const shouldRenderPublicationStatus =
    isTestDomain(domain_url) &&
    isFreePlanRestrictions &&
    !(isFreePlanRestrictions && hasAllowTestAutoDomainFeatureFlag)

  return (
    <Fragment>
      <StatusIcon variant={is_published ? 'active' : 'inactive'} />

      {shouldRenderPublicationType && (
        <Fragment>
          <Spreader spread={10} />

          <PublicationType publicationType={publication_type} />
        </Fragment>
      )}

      <Spreader spread={10} />

      {shouldRenderPublicationStatus && (
        <Fragment>
          <Paragraph color={is_published ? 'success' : 'neutral-4'}>
            {is_published ? t('word.published') : t('word.not.published')}
          </Paragraph>

          <Spreader spread={10} />
        </Fragment>
      )}
    </Fragment>
  )
}
