import { AxiosError } from 'axios'
import useSWR from 'swr'

export type DownloadLeadsResponse = {
  url: string
}

export const useDownloadLeads = ({
  key,
  file
}: {
  key: string
  file: string
}) => {
  const { data, isLoading, error } = useSWR<DownloadLeadsResponse, AxiosError>(
    `export/download/${key}/${file}`
  )

  return { data, isLoading, error }
}
