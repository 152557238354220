import { useStyles } from '@hooks/useStyles'

import styles from './HoneypotInput.module.scss'

type HoneypotInputProps = {
  name: string
  type?: string
  i18n?: {
    label?: string
    placeholder?: string
  }
  error?: string
  touched?: boolean
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export function HoneypotInput({
  name,
  type = 'text',
  i18n,
  error,
  touched,
  value,
  onChange,
  onBlur
}: HoneypotInputProps) {
  // We should prevent names like 'honeypot', 'trap', 'bot', 'robot', 'spam', or similar from appearing anywhere in the HTML
  const honeypotInputStyles = useStyles({
    [styles['input-wrapper']]: true
  })

  const hasError = Boolean(error && touched)

  return (
    <div className={honeypotInputStyles}>
      {i18n?.label && (
        <label className={styles['input-wrapper__label']} htmlFor={name}>
          {i18n.label}
        </label>
      )}

      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={name}
        tabIndex={-1}
        autoComplete='off'
        placeholder={i18n?.placeholder}
        className={styles['input-wrapper__input']}
      />

      {hasError && (
        <span className={styles['input-wrapper__error']}>{error}</span>
      )}
    </div>
  )
}

HoneypotInput.displayName = 'Input'
