import { PROD } from '@config/env'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { BEGINNING, COMPLETED } from '@constants/registrationSteps'
import { useFeatureFlags } from '@contexts/featureFlags'
import { getRegistrationErrorMessage } from '@helpers/errors'
import { hasLowerCase, hasNumber, hasUpperCase } from '@helpers/string'
import useQuery from '@hooks/useQuery'
import {
  Alert,
  Button,
  emitTimingToastToggle,
  InputForm,
  ShowPassword,
  Spacer,
  Spreader
} from '@landingi/landingi-ui-kit'
import { useAnimationContext } from '@pages/Authentication/contexts/animation'
import { isLastStep } from '@pages/Authentication/helpers/steps'
import { LANDINGS, REGISTRATION } from '@routes/path'
import {
  changeRegistrationStep,
  getAuthInfo,
  register
} from '@services/authentication'
import { emitTimingToast } from '@ui-kit'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { useSWRConfig } from 'swr'

import Checks from './Checks'
import { HoneypotInput } from './components/HoneypotInput/HoneypotInput'
import styles from './RegistrationForm.module.scss'
import registrationSchema from './RegistrationFormSchema'

/**
 * Registration form - stateful presentational component
 * @param {string} lang - current language used by user
 * @param {string} chosenPackage - package user is registering on
 * @param {string} variantName - variant of registration page, used as source parameter when registering
 * @param {string} source - source
 * @param {string} period - period
 * @param {string} pricing - pricing
 * @param {string} newSignup = newSignup
 * @param {object} props - props
 * @return {object} An object of children
 */
const RegistrationForm = ({
  period,
  lang,
  chosenPackage,
  source,
  pricing,
  newSignup,
  startFromFirstStep,
  isDefaultRegistration
}) => {
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const queryParams = useQuery()

  const hasAccessToSpa = useFeatureFlags('SPA_TOPBAR_SIDEBAR')

  const navigate = useNavigate()

  const { t } = useTranslation()
  const { playRegistrationToSurveyTransition } = useAnimationContext()

  /**
   * Handle input type state
   * @type {function}
   */
  const handleInputTypeState = useCallback(
    type =>
      type === 'text'
        ? setPasswordInputType('text')
        : setPasswordInputType('password'),
    []
  )

  const { mutate } = useSWRConfig()

  /**
   * handleSubmit - handles submit of user data and calls register endpoint
   * @param  {object} values - formik values
   * @param  {object} actions - formik actions
   * @type {function}
   */
  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      // lastName is honeypot field, should be empty
      const { firstName, lastName, email, password } = values

      const timeZone = moment.tz.guess()

      try {
        let token

        if (PROD) {
          token = await executeRecaptcha('registration')
        } else {
          token = true
        }

        const uri = window.location.href

        await register(
          firstName,
          lastName,
          email,
          password,
          lang,
          timeZone,
          chosenPackage,
          source,
          token,
          uri,
          pricing,
          newSignup,
          queryParams.get('promotion') || undefined,
          isDefaultRegistration ? 'yes' : 'no'
        )

        startFromFirstStep()

        const { data: authInfo } = await getAuthInfo()

        const { flow } = authInfo
        const { steps } = flow

        await changeRegistrationStep(BEGINNING)

        if (isLastStep(steps, BEGINNING)) {
          await changeRegistrationStep(COMPLETED)

          mutate('payments/discount', undefined, { revalidate: true })

          if (hasAccessToSpa) {
            navigate(LANDINGS.WELCOME)

            return
          }

          window.open(`${NEW_LANDINGI_APP_URL}/welcome`, '_self')

          return
        }

        await playRegistrationToSurveyTransition()

        await mutate('auth')

        navigate(`${REGISTRATION.SURVEY.DEFAULT}?lang=${lang}&period=${period}`)
      } catch (error) {
        emitTimingToastToggle(
          t(getRegistrationErrorMessage(error?.response?.data?.error)),
          'alert'
        )

        if (error?.response?.data?.error?.violations) {
          error.response.data.error.violations.forEach(violation => {
            const placeToSetError = violation.field
              .replace('[', '')
              .replace(']', '')

            const getErrorMessage = violation => {
              if (violation.field === '[firstName]') {
                return 'registration.flow.please.enter.valid.name'
              }

              switch (violation.message) {
                case 'Email address is already taken!':
                  return 'registration.flow.sign.up.a0008'
                case 'This value is too long. It should have 64 characters or less.':
                  return 'registration.flow.password.too.long'
                case 'Password is too weak!':
                  return 'registration.flow.password.too.weak'
                default:
                  return 'registration.flow.sign.up.other.error'
              }
            }

            const errorMessage = getErrorMessage(violation)

            setFieldError(placeToSetError, t(errorMessage))
          })
        }
      }
    },
    [executeRecaptcha]
  )

  const initialValues = {
    firstName: '',
    // Honeypot field
    lastName: '',
    email: '',
    password: ''
  }

  const {
    values,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    errors
  } = useFormik({
    initialValues,
    validationSchema: registrationSchema(),
    onSubmit
  })

  /**
   * isPasswordValid - returns true if all checks have passed
   * @param {string} password - password to validate
   */
  const isPasswordValid = (password = '') =>
    hasLowerCase(password) &&
    hasUpperCase(password) &&
    hasNumber(password) &&
    password.length > 9

  const handlePasswordFocus = () => {
    setIsPasswordTouched(true)
  }

  const getCheckers = () =>
    isPasswordTouched &&
    (isPasswordValid(values.password) ? (
      <Alert type='success'>
        <Trans i18nKey='registration.flow.password.alert' />
      </Alert>
    ) : (
      <Checks password={values.password} />
    ))

  const handleToastWhenIsNotValid = () => {
    if (!isValid) {
      emitTimingToast({
        type: 'error',
        message: t('error.form.is.not.valid')
      })
    }
  }

  return (
    <div data-testid='simple-registration-form'>
      <form onSubmit={handleSubmit} noValidate>
        <Column>
          <InputForm
            className={styles['registration-form__input']}
            field={{
              name: 'firstName',
              value: values.firstName,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            i18n={{
              placeholder: t('registration.flow.first.name'),
              label: t('registration.flow.first.name')
            }}
            form={{
              errors,
              touched
            }}
            id='firstName'
            autoFocus
          />

          <Spacer space='small' />

          <HoneypotInput
            name='lastName'
            i18n={{
              placeholder: t('registration.flow.last.name.placeholder'),
              label: t('registration.flow.last.name.label')
            }}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName}
            touched={touched.lastName}
          />

          <InputForm
            className={styles['registration-form__input']}
            field={{
              name: 'email',
              value: values.email,
              onChange: handleChange,
              onBlur: handleBlur
            }}
            i18n={{
              placeholder: t('registration.flow.work.email'),
              label: t('registration.flow.work.email')
            }}
            form={{
              errors,
              touched
            }}
            id='email'
          />

          <Spacer space='small' />

          <div className={styles['registration-form__password']}>
            <Row>
              <Column flexGrow='1'>
                <InputForm
                  className={styles['registration-form__input']}
                  field={{
                    name: 'password',
                    value: values.password,
                    onChange: handleChange,
                    onBlur: handleBlur
                  }}
                  i18n={{
                    placeholder: t('registration.flow.password'),
                    label: t('registration.flow.password')
                  }}
                  form={{
                    errors,
                    touched
                  }}
                  id='password'
                  type={passwordInputType}
                  onFocus={handlePasswordFocus}
                />
              </Column>

              <Spreader spread='tiny' />

              <Column>
                <ShowPassword setHidden={handleInputTypeState} />
              </Column>
            </Row>
          </div>

          <Spacer space='mini' />

          <Spacer space='tiny' />

          {getCheckers()}

          <Spacer space='tiny' />

          <Spacer space='mini' />

          <Button
            size='large'
            type='submit'
            isLoading={isSubmitting}
            onClick={handleToastWhenIsNotValid}
          >
            {t('registration.flow.sign.up.free')}
          </Button>
        </Column>
      </form>
    </div>
  )
}

RegistrationForm.propTypes = {
  lang: PropTypes.string.isRequired,
  chosenPackage: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired,
  pricing: PropTypes.string.isRequired,
  newSignup: PropTypes.string.isRequired,
  startFromFirstStep: PropTypes.func.isRequired,
  isDefaultRegistration: PropTypes.bool.isRequired
}

RegistrationForm.displayName = 'RegistrationForm'

export default RegistrationForm
