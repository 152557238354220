import { ADDONS_IDS } from '@constants/addonsIds'
import { smartSectionsPrices } from '@constants/addonsPrices'
import { ADDONS_UUIDS } from '@constants/addonsUuids'
import { PLANS } from '@constants/plans'
import SmartSections from '@images/addons/icons/icon_smart_section.svg'
import SmartSectionsThumbnail from '@images/addons/thumnail/smart_sections.png'
import {
  EDITOR,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { SmartSectionsScheduleDemoButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import {
  ADDON_GROUP,
  BUYABLE,
  BuyableAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getSmartSectionsAddon: (
  t: TFunction<'translation', undefined>
) => BuyableAddonTemplate = t => ({
  type: BUYABLE,
  name: t('addons.smart.sections.name'),
  title: t('addons.smart.sections.title'),
  tileDescription: <Trans i18nKey='addons.store.smart.section.description' />,
  badges: [EDITOR, SEVEN_DAYS_FREE_TRIAL],
  icon: SmartSections,
  imageSrc: SmartSectionsThumbnail,
  id: ADDONS_IDS.SMART_SECTIONS,
  uuid: ADDONS_UUIDS.SMART_SECTIONS,
  pageDescription: t('addons.store.smart.sections.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  price: smartSectionsPrices,
  pricing: getPricing(t),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  featureFlag: 'SMART_SECTIONS',
  secondaryButton: SmartSectionsScheduleDemoButton,
  limitedInPlans: [PLANS.FREE_LIMITED_24, PLANS.LITE_LIMITED_24],
  group: ADDON_GROUP.AUTOMATE
})
